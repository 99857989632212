<script setup lang="ts">
const emit = defineEmits(["accepted"]);

const consentDialogAccept = ref<HTMLElement>();

onMounted(() => {
  if (consentDialogAccept.value) consentDialogAccept.value.focus();
});
</script>

<template>
  <div
    class="privacy-footer"
    role="alertdialog"
    aria-labelledby="consentTitle"
    aria-describedby="consentDescription"
  >
    <div class="message">
      <h3 id="consentTitle" style="font-size: 1.6rem">Cookies</h3>
      <p id="consentDescription">
        This website uses cookies to give you the best browsing experience possible. If you continue
        to use this website without changing your browser's cookie settings you agree to this use of
        cookies. For more information visit
        <a href="/privacy" aria-label="One Times Square privacy notice"
          >https://www.onetimessquare.com/privacy</a
        >
      </p>
    </div>
    <div class="actions">
      <button
        ref="consentDialogAccept"
        class="accept"
        aria-label="Accept all cookies"
        @click="emit('accepted')"
      >
        Accept
      </button>
    </div>
  </div>
</template>

<style scoped lang="scss">
@use "../assets/scss/queries";
.privacy-footer {
  animation: transitionIn 500ms ease-in 5000ms 1 normal forwards;
  background: #222222;
  bottom: 0;
  color: #aaa;
  display: flex;
  justify-content: space-between;
  left: 0;
  opacity: 0;
  padding: 1rem 14rem;
  position: fixed;
  width: 100% !important;
  z-index: 50000;

  @include queries.for-size(phone-only) {
    padding: 1rem 6rem;
  }

  @include queries.for-size(tablet-landscape-down) {
    flex-direction: column;
  }

  .message {
    font-size: 1.2rem;
    text-align: left;

    p {
      line-height: 1.5;
    }
  }

  .actions {
    align-items: center;
    display: flex;
    justify-content: flex-end;
    padding-top: 15px;
    width: 100%;

    @include queries.for-size(tablet-landscape-down) {
      justify-content: center;
    }

    button,
    a {
      border: 1px #ffffff solid;
      border-radius: 22px;
      color: #ffffff;
      cursor: pointer;
      height: 40px;

      &.accept {
        background-color: #ffffff;
        color: #222222;
        font-weight: 700;
        padding-left: 3rem;
        padding-right: 3rem;
      }
    }
  }

  a {
    color: white !important;
    padding-top: 12px;
    text-decoration: none;
  }

  @keyframes transitionIn {
    0% {
      opacity: 0;
      transform: translateY(60px);
    }

    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
}
</style>
